<template>
  <v-list class="py-0 grey lighten-3" style="height: 100%">
    <h3 class="text-h6 font-weight-bold mb-4">
      Tài khoản ngân hàng ({{ bankAccounts.length }})
    </h3>
    <v-list-item
      class="rounded-lg white mb-3 bankAccount-list--item"
      :class="bankAccount.id ? 'bankAccount-add--disable' : null"
      link
      @click="bankAccount.id ? resetBankAccount() : null"
    >
      <v-list-item-content>
        <v-icon>mdi-plus</v-icon>
      </v-list-item-content>
    </v-list-item>

    <v-list class="list--scrollable py-0 grey lighten-3">
      <v-list-item-group
        v-model="computedCurrentBankAccountIndex"
        active-class="primary white--text font-weight-bold"
        color="primary"
      >
        <v-list-item
          class="bankAccount-list--item rounded-lg white mb-3"
          v-for="(item, index) in bankAccounts"
          :key="index"
          :title="`${item.number} - ${item.name}`"
          @click="selectBankAccount(item, index)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.number }} - {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-list>
</template>

<script>
export default {
  props: {
    currentBankAccountIndex: Number
  },

  computed: {
    bankAccount() {
      return this.$store.getters["BANK_ACCOUNT/companyBankAccount"];
    },

    bankAccounts() {
      return this.$store.getters["BANK_ACCOUNT/companyBankAccounts"];
    },

    computedCurrentBankAccountIndex: {
      get() {
        return this.currentBankAccountIndex;
      },
      set(val) {
        this.$emit("updateCurrentBankAccountIndex", val);
      }
    }
  },

  methods: {
    resetBankAccount() {
      this.$emit("updateCurrentBankAccountIndex", null);
      this.$store.dispatch("BANK_ACCOUNT/resetCompanyBankAccount");
    },

    selectBankAccount(bankAccount, index) {
      if (index === this.currentBankAccountIndex) return;

      this.$store.dispatch("BANK_ACCOUNT/setCompanyBankAccount", {
        ...bankAccount
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.list--scrollable {
  max-height: calc(100% - 104px);
  overflow-y: auto;
}
.bankAccount-list--item {
  &::before {
    border-radius: 0.5rem;
  }
}
</style>
