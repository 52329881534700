export const LIST_BANK_ACCOUNTS_FUNCTIONS = [
  {
    id: "DAT COC",
    text: "Đặt cọc"
  },
  {
    id: "THANH TOAN",
    text: "Thanh toán"
  }
];

export const BANK_ACCOUNT_TYPES = {
  COMPANY: {
    label: "Công ty"
  },
  BRANCH: {
    label: "Chi nhánh"
  }
};
