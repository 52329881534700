<template>
  <v-row class="pr-5">
    <v-col class="height--fixed px-5 py-0" cols="6" md="4" lg="3">
      <list-bank-accounts
        :current-bank-account-index="currentBankAccountIndex"
        @updateCurrentBankAccountIndex="currentBankAccountIndex = $event"
      ></list-bank-accounts>
    </v-col>
    <v-col class="height--fixed white py-4 px-5 rounded" cols="6" md="8" lg="9">
      <bank-account-detail
        :current-bank-account-index="currentBankAccountIndex"
        @updateCurrentBankAccountIndex="currentBankAccountIndex = $event"
      ></bank-account-detail>
    </v-col>
  </v-row>
</template>

<script>
import ListBankAccounts from "./components/ListBankAccounts";
import BankAccountDetail from "./components/BankAccountDetail";

import { INIT_COMPANY_BANK_ACCOUNT_VALUE } from "@/core/constant";

export default {
  components: {
    ListBankAccounts,
    BankAccountDetail
  },

  data() {
    return {
      currentBankAccountIndex: null,
      disabledAddBankAccount: false
    };
  },

  computed: {
    bankAccounts() {
      return this.$store.getters["BANK_ACCOUNT/companyBankAccounts"];
    }
  },

  async created() {
    await this.$store.dispatch("BANK_ACCOUNT/getCompanyBankAccounts");
  },

  methods: {
    addBankAccountItem() {
      this.disabledAddBankAccount = true;

      this.bankAccounts.unshift(
        JSON.parse(JSON.stringify(INIT_COMPANY_BANK_ACCOUNT_VALUE))
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.height--fixed {
  max-height: calc(100vh - 64px - 53px - 24px);
  overflow-x: hidden;
  overflow-y: auto;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 24px);
  }
}
</style>
