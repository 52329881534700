<template>
  <div class="pb-4" style="height: 100%">
    <!-- Start: Top -->
    <div class="d-flex align-center mb-4">
      <div class="text-h6 font-weight-bold">Thông tin tài khoản</div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="bankAccount.id"
        :disabled="
          bankAccountStatusRequest === 'loading-deleteCompanyBankAccount'
        "
        class="font-weight-bold mr-4"
        color="grey darkend-1"
        depressed
        icon
        rounded
        @click="deleteCompanyBankAccount()"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
      <v-btn
        v-else
        class="font-weight-bold grey--text text--darken-3 rounded-lg mr-4"
        color="grey lighten-3"
        depressed
        @click="resetBankAccount()"
      >
        Hủy
      </v-btn>
      <v-btn
        :loading="
          bankAccountStatusRequest === 'loading-updateCompanyBankAccount' ||
            bankAccountStatusRequest === 'loading-createCompanyBankAccount'
        "
        class="font-weight-bold rounded-lg"
        color="primary"
        depressed
        @click="
          bankAccountStatusRequest === 'loading-updateCompanyBankAccount' ||
          bankAccountStatusRequest === 'loading-createCompanyBankAccount'
            ? null
            : bankAccount.id
            ? updateBankAccount()
            : createBankAccount()
        "
      >
        {{ bankAccount.id ? "Lưu" : "Thêm" }}
      </v-btn>
    </div>
    <!-- End: Top -->
    <!-- Start: Account info -->
    <v-form ref="form">
      <v-row class="mt-3">
        <!-- Start: Bank -->
        <v-col class="py-0" cols="12" md="6">
          <div class="font-weight-bold mb-2">Ngân hàng (*)</div>
          <tp-autocomplete
            v-model="bankAccount.bank_id"
            :items="allBanks"
            item-text="name"
            item-value="id"
            placeholder="Chọn ngân hàng"
            validate="required"
          ></tp-autocomplete>
        </v-col>
        <!-- End: Bank -->
        <!-- Start: Account number -->
        <v-col class="py-0" cols="12" md="6">
          <div class="font-weight-bold mb-2">Số tài khoản (*)</div>
          <tp-text-field
            v-model="bankAccount.number"
            placeholder="Nhập số tài khoản"
            validate="required"
          ></tp-text-field>
        </v-col>
        <!-- End: Account number -->
        <!-- Start: Owner -->
        <v-col class="py-0" cols="12" md="6">
          <div class="font-weight-bold mb-2">Chủ tài khoản (*)</div>
          <tp-text-field
            v-model="bankAccount.name"
            placeholder="Nhập chủ tài khoản"
            validate="required"
          ></tp-text-field>
        </v-col>

        <v-col class="py-0" cols="12" md="6">
          <div class="font-weight-bold mb-2">Loại tài khoản (*)</div>

          <v-radio-group v-model="bankAccount.type" row>
            <v-radio
              v-for="(type, key) in BANK_ACCOUNT_TYPES"
              :key="key"
              :label="type.label"
              :value="key"
            >
            </v-radio>
          </v-radio-group>
        </v-col>

        <!-- End: Owner -->
        <!-- Start: Owner -->
        <v-col class="py-0" cols="12" md="6">
          <div class="font-weight-bold mb-2">Chức năng (*)</div>
          <tp-select
            v-model="bankAccount.functions"
            :items="LIST_BANK_ACCOUNTS_FUNCTIONS"
            deletable-chips
            hide-selected
            item-text="text"
            item-value="id"
            multiple
            placeholder="Chọn chức năng"
            small-chips
            validate="required"
          ></tp-select>
        </v-col>

        <v-col class="py-0" cols="6" md="3">
          <div class="font-weight-bold mb-2">Trạng thái (*)</div>
          <v-switch
            v-model="bankAccount.enable"
            :false-value="false"
            :true-value="true"
            color="green"
            dense
            hide-details
            label="Đang hoạt động"
          ></v-switch>
        </v-col>

        <v-col class="py-0" cols="6" md="3">
          <div class="font-weight-bold mb-2">Trạng thái check giao dịch</div>
          <v-switch
            v-model="bankAccount.is_transaction_checking"
            :false-value="false"
            :true-value="true"
            color="green"
            dense
            hide-details
            label="Đang hoạt động"
          ></v-switch>
        </v-col>

        <!-- Start: Owner -->
        <v-col class="py-0" cols="12" md="12">
          <div class="font-weight-bold mb-2">Chi nhánh khả dụng (*)</div>
          <tp-select
            v-model="bankAccount.branches"
            :items="allSaleBranches"
            deletable-chips
            hide-selected
            item-text="name"
            item-value="id"
            multiple
            placeholder="Chọn chi nhánh"
            small-chips
            validate="required"
          ></tp-select>
        </v-col>
        <!-- End: Owner -->

        <v-col class="py-0" cols="12" md="12">
          <div class="mb-2">
            <span class="font-weight-bold">Mật khẩu</span> (Chỉ cần đặt với các
            tài khoản cần tự động xác nhận giao dịch chuyển khoản)
          </div>

          <v-row>
            <v-col class="py-0" cols="12" md="6">
              <tp-text-field
                v-model="bankAccount.password"
                mn="uix"
                placeholder="Nhập mật khẩu"
                type="password"
              ></tp-text-field>
            </v-col>
            <v-col class="py-0" cols="12" md="6">
              <tp-text-field
                v-model="bankAccount.confirm_password"
                placeholder="Nhập lại mật khẩu"
                type="password"
              ></tp-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <!-- End: Account info -->
  </div>
</template>

<script>
import {
  LIST_BANK_ACCOUNTS_FUNCTIONS,
  BANK_ACCOUNT_TYPES
} from "@/modules/System/constants";

export default {
  props: {
    currentBankAccountIndex: {
      type: Number
    }
  },

  data() {
    return {
      LIST_BANK_ACCOUNTS_FUNCTIONS,
      BANK_ACCOUNT_TYPES
    };
  },

  computed: {
    allBanks() {
      return this.$store.getters["BANK_ACCOUNT/allBanks"];
    },

    allSaleBranches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    bankAccount() {
      return this.$store.getters["BANK_ACCOUNT/companyBankAccount"];
    },

    bankAccounts() {
      return this.$store.getters["BANK_ACCOUNT/companyBankAccounts"];
    },

    bankAccountStatusRequest() {
      return this.$store.getters["BANK_ACCOUNT/statusRequest"];
    }
  },

  created() {
    if (this.allBanks.length === 0) {
      this.$store.dispatch("BANK_ACCOUNT/getAllBanks");
    }

    if (this.allSaleBranches.length === 0) {
      this.$store.dispatch("BRANCH/getAllBranches");
    }
  },

  methods: {
    async createBankAccount() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      // Create request
      await this.$store.dispatch(
        "BANK_ACCOUNT/createCompanyBankAccount",
        this.bankAccount
      );

      // Alert
      if (
        this.bankAccountStatusRequest.value ===
        "success-createCompanyBankAccount"
      ) {
        // Update current index
        if (!this.currentBankAccountIndex) {
          this.$emit(
            "updateCurrentBankAccountIndex",
            this.bankAccounts.length - 1
          );
        }

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm tài khoản thành công"
          }
        });
      } else if (
        this.bankAccountStatusRequest.value === "error-createCompanyBankAccount"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.bankAccountStatusRequest.message.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async deleteCompanyBankAccount() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa tài khoản <strong>${this.bankAccount.number} - ${this.bankAccount.name}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "BANK_ACCOUNT/deleteCompanyBankAccount",
                this.bankAccount.id
              );

              if (
                this.bankAccountStatusRequest.value ===
                "success-deleteCompanyBankAccount"
              ) {
                await this.$store.dispatch(
                  "BANK_ACCOUNT/resetCompanyBankAccount"
                );

                await this.$store.dispatch(
                  "BANK_ACCOUNT/getCompanyBankAccounts"
                );

                this.$emit("updateCurrentBankAccountIndex", null);
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa tài khoản"
                  }
                });
              }
            }
          }
        }
      });
    },

    resetBankAccount() {
      this.$emit("updateCurrentBankAccountIndex", null);
      this.$store.dispatch("PAYMENT_ACCOUNT/resetBankAccount");
    },

    async updateBankAccount() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      // Update request
      await this.$store.dispatch("BANK_ACCOUNT/updateCompanyBankAccount", {
        companyBankAccount: this.bankAccount,
        companyBankAccountIndex: this.currentBankAccountIndex
      });

      // Alert
      if (
        this.bankAccountStatusRequest.value ===
        "success-updateCompanyBankAccount"
      ) {
        //
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu thay đổi"
          }
        });
      } else if (
        this.bankAccountStatusRequest.value === "error-updateCompanyBankAccount"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.bankAccountStatusRequest.message.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.scrollable {
  max-height: calc(100% - 113px);
  overflow-y: scroll;
}
</style>
